.embed {
  box-sizing: border-box;
  background: var(--background-primary);
  border-radius: 4px;
  width: 100%;
  display: grid;
  position: relative;
}

.embedFull {
  border-left: 4px solid var(--background-tertiary);
  background: var(--background-primary);
  border: 1px solid var(--background-header-secondary);
  border-left-width: 4px;
}

.gridContainer {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.grid {
  grid-template-rows: auto;
  grid-template-columns: minmax(0, 1fr);
  gap: 8px;
  padding: .5rem 1rem 1rem .75rem;
  display: grid;
}

.grid.hasThumbnail {
  grid-template-columns: minmax(0, 1fr) auto;
  column-gap: 16px;
}

.embedContent {
  grid-template-columns: minmax(0, 1fr);
  gap: 8px;
  min-width: 0;
  display: grid;
}

.embedContent > :first-child {
  margin-top: 8px;
}

.embedProvider {
  overflow-wrap: break-word;
  word-break: break-word;
  grid-column: 1 / 1;
  min-width: 0;
  font-size: .75rem;
  line-height: 1rem;
}

.embedProvider .embedLink {
  color: var(--interactive-normal);
}

.embedProvider .embedLink:hover {
  color: var(--interactive-hover);
}

.embedAuthor {
  grid-column: 1 / 1;
  align-items: center;
  min-width: 0;
  display: flex;
  overflow: hidden;
}

.embedAuthorName {
  color: var(--text-chat);
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  font-size: .875rem;
  font-weight: 600;
  overflow: hidden;
}

.embedAuthorNameLink {
  color: var(--text-normal) !important;
}

.embedAuthorIcon {
  object-fit: contain;
  border-radius: 50%;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.embedTitle {
  color: var(--embed-title);
  overflow-wrap: break-word;
  word-break: break-word;
  grid-column: 1 / 1;
  min-width: 0;
  max-width: 100%;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.embedLink {
  -webkit-text-decoration: var(--link-decoration);
  text-decoration: var(--link-decoration);
  cursor: pointer;
  color: var(--text-link);
}

.embedLink:hover {
  text-decoration: underline;
}

.embedDescription {
  white-space: pre-line;
  color: var(--text-chat);
  overflow-wrap: break-word;
  word-break: break-word;
  grid-column: 1 / 1;
  max-width: 100%;
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedFields {
  grid-column: 1 / 1;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  width: 100%;
  margin-top: 8px;
  display: grid;
}

.embedField {
  overflow-wrap: break-word;
  word-break: break-word;
  min-width: 0;
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedFieldName {
  min-width: 0;
  color: var(--embed-title);
  overflow-wrap: break-word;
  word-break: break-word;
  margin-bottom: 2px;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.125rem;
}

.embedFieldValue {
  white-space: pre-line;
  min-width: 0;
  color: var(--text-chat);
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: .875rem;
  line-height: 1.125rem;
}

.embedMedia {
  contain: paint;
  border-radius: 4px;
  grid-column: 1 / 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  display: flex;
  overflow: hidden;
}

.hasThumbnail .embedMedia {
  grid-column: 1 / 3;
}

.embedThumbnail {
  border-radius: 4px;
  flex-shrink: 0;
  grid-area: 1 / 2 / 6 / 2;
  justify-self: end;
  max-width: 80px;
  height: 80px;
  margin-top: 8px;
  margin-left: 16px;
  overflow: hidden;
}

.embedFooterContainer {
  grid-column: 1 / -1;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  margin-top: 8px;
}

.embedFooter {
  align-items: flex-start;
  min-width: 0;
  max-width: 100%;
  display: flex;
}

.hasThumbnail .embedFooterContainer {
  grid-column: 1 / 3;
}

.embedFooterText {
  color: var(--text-chat);
  overflow-wrap: break-word;
  word-break: break-word;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  max-width: 100%;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
  display: flex;
}

.embedFooterIcon {
  object-fit: contain;
  border-radius: 50%;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.embedFooterSeparator {
  background-color: var(--text-chat);
  vertical-align: middle;
  border-radius: 50%;
  flex-shrink: 0;
  width: 4px;
  height: 4px;
  margin: 0 4px;
  display: inline-block;
}

.embedWrapper {
  align-self: flex-start;
  width: 100%;
  overflow: hidden;
}

.embedImage {
  justify-content: center;
  width: 100%;
  max-width: 100%;
  display: flex;
}

.embedFlexGrow {
  flex-grow: 1;
}

.justifyAuto {
  justify-content: flex-start;
}
